.app__signup,
.app__signin {
  display: flex;
  flex-direction: column;

  input {
    padding: 10px 0;
    margin: 10px;
  }
}
