.app {
  font-family: sans-serif;
  text-align: center;
}

* {
  margin: 0;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.cards__header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}

.cards__header_block {
  display: flex;
  padding: 20px;
}

.app__headerImage {
  object-fit: contain;
}

.app__posts {
  padding: 20px;
}
