@import "./variables.scss";

.imageupload {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  border-top: 1px solid lightgray;
  background-color: $backgorundColor;
  justify-content: center;
  align-items: center;
  min-height: 330px;
  position: relative;
}

.imageupload__progress {
  width: 100%;
  padding: 10px 0;
}

.imageupload__clear {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
}

.imageupload__button {
  height: 300px;
  width: 100%;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}

.imageupload__button_cover {
  background-color: #f3f3f3;
  width: 100%;
  position: absolute;
  text-align: center;
}

.imageupload__preview {
  background-color: white;
  height: 300px;
  object-fit: contain;
  position: absolute;
  text-align: center;
}
