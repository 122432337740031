@import "./../commons/variables.scss";

.newRecipe__formBlock {
  display: flex;
}

.newRecipe__form {
  display: flex;
  flex-direction: column;
}

Button.newRecipe__button {
  background-color: $Primary;
  color: white;
}

Button.newRecipe__button:hover {
  background-color: $Primary;
}
