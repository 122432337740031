@import "./../commons/variables.scss";

.newRecipe__modal {
  width: 80%;
  border: 1px solid #eee;
  padding: 16px 32px 24px;
  overflow: auto;
  position: absolute;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 93vh;
  background-color: #fff;
}

.newRecipe__title {
  margin-right: 10px;
}

Button.newRecipe__submit {
  background-color: $Primary;
  color: white;
}

.btn-box {
  padding: 0 0 20px 0;
}
