@import "./../commons/variables.scss";

.card {
  padding: 20px;
  min-width: 300px;
  position: relative;

  @media (max-width: $breakpoint-small) {
    width: 100%;
    max-width: none;
  }
}

.card__title {
  padding: 15px 0;
}

.tr {
  display: flex;
  justify-content: space-between;
}

.td {
  padding: 10px;
}

.card__steps {
  counter-reset: li;
}

.card__step {
  position: relative;
  list-style: none;
  padding: 10px;

  &:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    top: 0;
    left: -30px;
    font-size: 30px;
    line-height: 1.3;
    font-weight: 700;
    color: $Primary;
  }
}
